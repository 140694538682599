<template>
  <div class="app-embed">
    <div class="app-embed__main">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.app-embed {
  --primary: #666;
  --secondary: #fff;
  --grey-light: #eee;
  --compass-red: #fe007c;
  --black: #2b282b;

  --heading-font-family: var(--body-font-family);
  --heading-font-weight: 400;
  --heading-line-height: 1.1;
  min-height: 100vh;

  color: var(--primary);
  background-color: var(--secondary);

  &__main {
    position: relative;
    overflow: hidden;
  }
}
</style>
